import React from 'react';
import classNames from 'classnames';
import { block } from './constants';

type SideNavItemProps = React.HTMLAttributes<HTMLLIElement> & {
  isActive?: boolean;
  isAlert?: boolean;
};

export const SideNavItem = ({
  className,
  isActive = false,
  isAlert = false,
  ...rest
}: SideNavItemProps): React.ReactElement => {
  const wrapperClassName = classNames(
    `${block}__item`,
    {
      [`${block}__item--active`]: isActive,
      [`${block}__item--alert`]: isAlert,
    },
    className
  );
  return <li {...rest} data-has-alert={isAlert} className={wrapperClassName} />;
};
